import data from '../app/our-work/data.json';
import { getUniqueValues } from '@/helpers/insights';
import { ICardPreview } from '@/interfaces/layout';

export interface IOptionType {
  value: industryCategories;
  label: industryCategories;
  image: {
    src: string | null;
  };
}
export const TABS_SELECTOR_ID = 'our-work-tabs';

export const ITEMS_PER_PAGE = {
  desktop: 6,
  tablet: 4,
  mobile: 3,
};

export const MAX_AMOUNT_OF_CARDS = 5;

const list = data.cases.list;
const getAllTags = (list: ICardPreview[]) => list.flatMap((item) => item.tags.map((tag) => tag.title));

export const allIndustriesUniqueTags = getUniqueValues(getAllTags(list as ICardPreview[])).sort();

export enum industryCategories {
  FINANCE = 'Finance',
  HEALTHCARE = 'Healthcare',
  UTILITY = 'Utility',
  STARTUPS = 'Startups',
  EDUCATION = 'Education',
  CONSTRUCTION = 'Construction',
  AUTOMOTIVE = 'Automotive',
  TECHNOLOGY = 'Technology',
  ERP = 'ERP',
  COMPLIANCE = 'Compliance',
}

export const industriesTagImages = (tagname: string) => {
  const tagsImageMap: { [key: string]: string } = {
    [industryCategories.FINANCE]: '/icons/industries/finance-link.svg',
    [industryCategories.HEALTHCARE]: '/icons/industries/healthcare-link.svg',
    [industryCategories.UTILITY]: '/icons/industries/energy-link.svg',
    [industryCategories.STARTUPS]: '/icons/industries/startup-link.svg',
    [industryCategories.EDUCATION]: '/icons/industries/education-link.svg',
    [industryCategories.CONSTRUCTION]: '/icons/industries/construction-link.svg',
    [industryCategories.AUTOMOTIVE]: '/icons/industries/automotive-link.svg',
    [industryCategories.TECHNOLOGY]: '/icons/industries/technology-link.svg',
    [industryCategories.ERP]: '/icons/industries/erp-link.svg',
    [industryCategories.COMPLIANCE]: '/icons/industries/compliance-link.svg',
  };

  return tagsImageMap[tagname] || null;
};

export enum themeColors {
  PURPLE = 'purple',
  PINK = 'pink',
  DARK_PINK = 'darkPink',
  GREEN = 'green',
  WHITE = 'white',
}

export const defaultIndustries = [
  industryCategories.FINANCE,
  industryCategories.HEALTHCARE,
  industryCategories.UTILITY,
  industryCategories.TECHNOLOGY,
  industryCategories.STARTUPS,
  industryCategories.EDUCATION,
  industryCategories.AUTOMOTIVE,
  industryCategories.CONSTRUCTION,
  industryCategories.COMPLIANCE,
  industryCategories.ERP,
];

export const industriesOptions: IOptionType[] = defaultIndustries.map((tag) => ({
  label: tag,
  value: tag,
  image: {
    src: industriesTagImages(tag),
  },
}));

export const includedCases: Record<industryCategories, string[]> = {
  [industryCategories.FINANCE]: [
    'game-changing-financial-platform',
    'big-data-processing-platform',
    'transforming-transactions-with-an-fintech-platform',
  ],
  [industryCategories.HEALTHCARE]: [
    'healthcare-system-education-portal',
    'expert-teeth-whitening-network',
    'drug-reviews-automation-tool',
  ],
  [industryCategories.UTILITY]: [
    'energy-management-platform-vs-utility-bills',
    'refactoring-legacy-software-for-a-water-management-business',
    'decentralized-water-systems-empowered-by-software',
  ],
  [industryCategories.TECHNOLOGY]: [
    'supporting-an-ethical-compliance-system-throughout-its-acquisition',
    'automation-of-a-dentist-clinics-business-processes',
    'a-reliable-sales-management-system',
    'erp-system-for-a-technology-company',
    'online-call-recording-application',
    'commercial-leasing-platform-for-real-estate-company',
    'video-conferencing-application-for-courts',
  ],
  [industryCategories.STARTUPS]: [
    'ultimate-social-media-manager',
    'transforming-transactions-with-an-fintech-platform',
    'decentralized-water-systems-empowered-by-software',
  ],
  [industryCategories.EDUCATION]: [
    'healthcare-system-education-portal',
    'drivers-education-platform',
    'learning-platform',
  ],
  [industryCategories.AUTOMOTIVE]: [
    'crm-system-for-a-car-reseller',
    'web-application-for-trading-cars',
    'mobile-app-for-vehicle-sales-on-the-move',
  ],
  [industryCategories.CONSTRUCTION]: [
    'autocad-plugin-to-streamline-land-survey-creation',
    'steel-estimation-solutions-wizard',
    'erp-system-for-a-plumbing-business',
  ],
  [industryCategories.COMPLIANCE]: [
    'supporting-an-ethical-compliance-system-throughout-its-acquisition',
    'system-for-tracking-and-storing-testing-results',
  ],
  [industryCategories.ERP]: [
    'erp-solution-for-a-wholesale-operator',
    'erp-system-for-a-plumbing-business',
    'erp-system-for-a-technology-company',
  ],
};

export const initialCases = [
  'decentralized-water-systems-empowered-by-software',
  'energy-management-platform-vs-utility-bills',
  'drivers-education-platform',
  'crowdfunding-platform-for-textbooks',
  'automation-of-a-dentist-clinics-business-processes',
  'educational-communication-platform-for-schools-and-colleges',
  'home-inventory-management-system-for-real-estate-managers',
];
